<template>
    <div class="animated fadeIn">
        <LoadingWidget v-if="initStatus != 1" :status="initStatus" @init="init" />
        <b-card v-else>
            <template v-slot:header>
                <h5>Data Aset Lancar</h5>
            </template>
            <b-row class="mb-3" no-gutters>
                <b-col md="3" class="mr-2">
                    <b-form-group label="Lokasi" label-for="filterInput">
                        <b-form-select v-model="lokasi" id="filterStatus" name="status" value-field="id" text-field="name">
                            <template v-slot:first>
                                <b-form-select-option :value="null" disabled>--- Pilih Lokasi ---</b-form-select-option>
                            </template>
                            <b-form-select-option v-for="lokasi in lokasiOpt" :value="lokasi.id">{{ lokasi.name
                            }}</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col md="3" class="mr-2">
                    <label>Cabang</label>
                    <multiselect v-model="cabang" :options="cabangAll" :internal-search="true" :allow-empty="false"
                        deselect-label="" select-label="" label="name" track-by="kode" placeholder="-- Pilih Penerima --"
                        :disabled="lokasi == null ? true : false" />
                </b-col>

                <b-col md="4" class="mr-2">
                    <label>Filter</label>
                    <b-form-group label-for="filterInput">
                        <b-form-input class="mr-2" name="filterInvoice" id="filterInput" type="search"
                            placeholder="No Invoice" v-model="filter.search" @keyup="getStok()"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="3">
                    <b-form-group label-for="filterDate" label="Dari Tangal">
                        <b-form-input v-model="filter.dateFrom" name="filterFromDateTarik" id="filterDate"
                            @change="filterDate" type="date"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col sm="3">
                    <b-form-group label-for="filterDate" label="Hingga Tangal">
                        <b-form-input v-model="filter.dateTo" name="filterToDateTarik" @change="filterDate"
                            type="date"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="3">
                    <b-button variant="pgiBtn" :disabled="isBusy" @click="onReset()">Reset</b-button>
                </b-col>
            </b-row>
            <b-row class="justify-content-end mr-2">
                <div class="form-group">
                    <b-button v-b-modal="'confirm-download'" variant="pgiBtn">
                        <i class="fa fa-file-excel-o mr-2"></i> Laporan
                    </b-button>
                </div>
            </b-row>
            <!-- <b-row class="justify-content-end mb-3" style="margin-right: 10px">
                <div>
                    <b-button class="mr-2" variant="pgiBtn" name="okeDownloadBtn" :disabled="loading" type="submit"
                        form="periodeDownloadSuratForm">
                        <i class="fa fa-download mr-2"></i>
                        Excel
                    </b-button>

                    <b-button variant="pgiBtn" name="okeDownloadBtn" :disabled="loading" type="submit"
                        form="periodeDownloadSuratForm">
                        <i class="fa fa-download mr-2"></i>
                        PDF
                    </b-button>
                </div>
            </b-row> -->

            <b-table bordered hover show-empty :busy="isBusy" :items="stok" :fields="fields" :no-local-sorting="false"
                @sort-changed="sortingChanged" responsive class="mb-3">
                <template v-slot:table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong> Loading data...</strong>
                    </div>
                </template>
                <template v-slot:empty>
                    Tidak ada data tipe barang.
                </template>

                <template v-slot:cell(action)="row">
                    <div class="no-wrap">
                        <b-button size="sm" class="mr-3 table-button view" v-b-tooltip.hover title="Print Barcode"
                            name="stokViewBtn">
                            <i class="fa fa-qrcode"></i>
                        </b-button>
                    </div>
                </template>
            </b-table>
            <div class="pagination-wrapper d-flex align-items-center justify-content-between">
                <b-input-group class="align-items-center tableShowItem">
                    <span class="ml-2">{{ totalViewText }}</span>
                </b-input-group>
                <b-pagination class="mb-0" v-model="currentPage" :total-rows="rows" :per-page="perPage"
                    aria-controls="my-table" @input="onPageChange"></b-pagination>
            </div>
        </b-card>

        <!-- MODAL DOWNLOAD -->
        <b-modal id="confirm-download" centered title="Download Laporan" no-close-on-backdrop no-close-on-esc hide-footer>
            <div v-if="filter.dateFrom && filter.dateFrom" class="row justify-content-between align-items-center mb-3">
                <div class="col-md-8">
                    <div class="d-flex align-items-center">
                        <div class="mr-1">Peridode : </div>
                        <div class="">{{ filter.dateFrom }}</div>
                        <div class="mx-1">s/d</div>
                        <div class="">{{ filter.dateTo }}</div>
                    </div>
                </div>
                <div class="col-md-4 text-md-right mt-2 mt-md-0">
                    <b-button @click="downloadPeriod" class="mr-2" size="sm" variant="pgiBtn" :disabled="loading"
                        type="button">
                        <i class="fa fa-download"></i> Download
                    </b-button>
                </div>
            </div>
            <div class="row justify-content-between align-items-center">
                <div class="col-md-8">
                    Laporan saat ini
                </div>
                <div class="col-md-4 text-md-right mt-2 mt-md-0">
                    <b-button @click="downloadLive" class="mr-2" size="sm" variant="pgiBtn" :disabled="loading"
                        type="button">
                        <i class="fa fa-download"></i> Download
                    </b-button>
                </div>
            </div>
        </b-modal>
        <!-- MODAL DOWNLOAD -->

    </div>
</template>

<script>
import axiosClient from '../../../config/api';

export default {
    name: 'master-tipe-barang',
    data() {
        return {
            initStatus: 0,
            isBusy: true,
            isSubmitting: false,
            loadingDownload: false,
            input: {
                show: 10,
            },
            fields: [
                { key: "no", label: "No" },
                { key: "lokasi", label: "Lokasi" },
                { key: "noBarang", label: "No Barang" },
                { key: "namaBarang", label: "Nama Barang" },
                { key: "imei", label: "Imei" },
                { key: "detailBarang", label: "Detail Barang" },
                { key: "qty", label: "Qty" },
                { key: "nominalAset", label: "Nominal Aset" },
                { key: "action", label: "Aksi" }
            ],
            filter: {
                search: null,
                dateFrom: null,
                dateTo: null,
            },

            stok: [],

            lokasi: null,
            lokasiOpt: [
                { id: 1, name: "Kantor Pusat" },
                { id: 2, name: "Kantor Cabang" },
            ],
            cabangAll: [],
            cabang: [],
            currentPage: 1,
            perPage: null,
            rows: null,
            totalViewText: "",
            page: '',
            size: '',
            from: '',
            limit: '',
            loading: false,
            query: "",
        }
    },

    methods: {
        //----------------- Fungsi Inisialisasi -----------------
        init() {
            this.initStatus = 0

            if (localStorage.getItem("role_id") == 7) {
                this.$axios.get('api/admin/cabang/all').then(response => {
                    console.log(response.data.data);
                    let datas = response.data.data
                    this.cabangAll = datas
                })
            }

            let getStok = this.$axios.get("api/admin/barang-stok/asset?id_kategori=2").then(response => {
                console.log(response.data.data);

                let datas = response.data.data
                let i = 1
                for (var data of datas.data) {

                    this.stok.push({
                        no: i,
                        lokasi: data.cabang.name,
                        namaBarang: data.barang_tipe.tipe,
                        noBarang: data.nomer_barang,
                        imei: data.imei,
                        detailBarang: data.detail_barang,
                        qty: data.jumlah_stok,
                        nominalAset: data.total_asset,
                    })
                    i++
                }

                this.perPage = datas.per_page
                this.size = this.perPage
                this.from = datas.from
                this.limit = datas.to
                this.loading = false

                this.updateTotalItem(datas.total)
                let msg = this.$helper.getFlash()
                this.$helper.toastSucc(this, msg)
            })

            Promise.all([getStok]).then(() => {
                this.initStatus = 1
                this.toggleBusy()
            })
                .catch(error => {
                    console.log(error)
                    this.initStatus = -1
                })
        },

        getStok() {
            this.toggleBusy()

            console.log(this.filter.search);
            //console.log(this.filter.tipe);
            //console.log(this.filter.golongan);
            if (this.filter.dateFrom != null) this.query = this.query + "&startDate=" + this.filter.dateFrom + "&endDate=" + this.filter.dateTo
            if (this.filter.search != null) this.query = this.query + "&search=" + this.filter.search
            //if (this.filter.merk != null) this.query = this.query + "&id_kategori=" + this.filter.golongan
            console.log(this.query);
            this.$axios.get("api/admin/barang-stok/asset?id_kategori=2" + this.query).then(response => {
                console.log(response.data.data);
                this.stok = []
                let datas = response.data.data
                let i = datas.from
                for (var data of datas.data) {
                    this.stok.push({
                        no: i,
                        lokasi: data.cabang.name,
                        namaBarang: data.barang_tipe.tipe,
                        noBarang: data.nomer_barang,
                        imei: data.imei,
                        detailBarang: data.detail_barang,
                        qty: data.jumlah_stok,
                        nominalAset: data.total_asset,
                    })
                    i++
                }

                this.perPage = datas.per_page
                this.size = this.perPage
                this.from = datas.from
                this.limit = datas.to
                this.loading = false

                this.toggleBusy()
                this.updateTotalItem(datas.total)
                let msg = this.$helper.getFlash()
                this.$helper.toastSucc(this, msg)

            })
                .catch(error => {
                    console.log(error)
                    console.log(error.response.data.reason)
                    this.$helper.parseError(this, error)
                    this.toggleBusy()
                    this.loading = false
                })
        },

        //----------------- Fungsi Pagination, Sorting, dan Filtering -----------------

        onLimitChange() {
            this.size = this.input.show
            this.perPage = this.input.show
            this.currentPage = 1
            this.page = 0
            this.query = this.$helper.makeQuery(0, this.size)
            this.getStok()
        },

        onPageChange() {
            if (this.loading == false) {
                console.log(this.currentPage);
                this.page = this.currentPage
                this.query = "&page=" + this.page

                this.getStok()
            }
        },

        sortingChanged(ctx) {
            if (ctx.sortBy == "merk.name") {
                this.input.sortBy = "merk"
                this.input.sortDesc = ctx.sortDesc
                this.onFilter()
            } else if (ctx.sortBy != "") {
                this.input.sortBy = ctx.sortBy
                this.input.sortDesc = ctx.sortDesc
                this.onFilter()
            }
        },

        onReset() {
            this.filter.search = null
            // this.currentPage = 1
            // this.page = 0
            this.query = "&page=1"
            this.loading = true
            this.getStok()
        },


        filterDate() {

            console.log(this.filter.dateFrom);
            console.log(this.filter.dateTo);

            if (this.filter.dateFrom != null && this.filter.dateTo != null) {
                this.getStok()
            }
        },



        //----------------- Fungsi Pindah Halaman dan Fungsi Lain -----------------

        toggleBusy() {
            if (this.isBusy == true) this.isBusy = false
            else this.isBusy = true
        },


        updateTotalItem(total) {
            this.perPage = this.size
            this.rows = total
            if (this.limit > this.rows) limit = this.rows
            this.totalViewText = "Menampilkan " + this.from + " - " + this.limit + " dari " + this.rows + " entri"
        },


        // DOWNLOAD
        async downloadPeriod() {
            try {
                this.loadingDownload = true
                const response = await axiosClient({
                    url: `admin/barang-stok/export-stok-barang-date`,
                    method: 'GET',
                    params: {
                        start: this.filter.dateFrom,
                        end: this.filter.dateTo,
                        id_kategori: 2
                    },
                    responseType: 'blob',
                })
                if (response.status === 200) {
                    const url = URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute(
                        'download',
                        `laporan-asset-lancar-${this.filter.dateFrom}-sd-${this.filter.dateTo}.xlsx`
                    )
                    document.body.appendChild(link)
                    link.click()
                }
            } catch (error) {
                console.log(error)
            }
            finally {
                this.loadingDownload = false
            }

        },
        async downloadLive() {
            try {
                this.loadingDownload = true
            } catch (error) {
                const response = await axiosClient({
                    url: `admin/barang-stok/export-stok-barang-live`,
                    method: 'GET',
                    params: {
                        id_kategori: 2
                    },
                    responseType: 'blob',
                })
                if (response.status === 200) {
                    const url = URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute(
                        'download',
                        `laporan-asset-lancar-${new Date().toDateString()}.xlsx`
                    )
                    document.body.appendChild(link)
                    link.click()
                }
            } finally {
                this.loadingDownload = false
            }

        }
        // DOWNLOAD

    },

    watch: {

        //----------------- Pantau Filter Jenis Barang -----------------

    },

    created() {
        this.init()
    }
}
</script>
